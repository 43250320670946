<template>
  <div>
    <fieldsRows
        :templateContent="templateContent"
        @field-value-changed="updateFieldValue(...arguments)"
        :valueLine="{...value,type: simType,slot: simSlot,ratType: radioRatType }"/>
  </div>
</template>
<script>
import fieldsRows from '@/commonComponents/fieldsRows.vue';
import options from "@/cfg/options.json";

export default {
  components: {fieldsRows},
  props: ['field', 'fieldAttributes', 'result', 'value', 'valueLine'],
  data() {
    return {
      options: options,
    };
  },
  computed: {
    simType(){
      let simType ="";
      if(typeof this.value!="undefined" &&
          typeof this.value.sim!="undefined" &&
          typeof this.value.sim.type!="undefined"
      ){
        simType= this.value.sim.type;
      }
      return simType;
    },
    simSlot(){
      let simSlot ="";
      if(typeof this.value!="undefined" &&
          typeof this.value.sim!="undefined" &&
          typeof this.value.sim.slot!="undefined"
      ){
        simSlot= this.value.sim.slot;
      }
      return simSlot;
    } ,
    radioRatType(){
      let radioRatType ="";
      if(typeof this.value!="undefined" &&
          typeof this.value.radio!="undefined" &&
          typeof this.value.radio.ratType!="undefined"
      ){
        radioRatType= this.value.radio.ratType;
      }
      return radioRatType;
    } ,
    templateContent() {
      let templateContent = {
        fields: {
          pingLine: {
            type: 'line',
            fields: {
              type: {
                type: 'select',
                name: 'SIM Type',
                associatedOption: ['local'],
                setEmpty: false,
                ignoreEmptyAdd: true,
                default:"local",
                colAttrs: {cols:1},
              },
              slot: {
                type: 'combobox',
                name: 'SIM Slot',
                associatedOption: [
                  [1, '1'],
                  [2, '2'],
                  [3, '3'],
                  [4, '4'],
                  [5, '5'],
                  [6, '6'],
                  [7, '7'],
                  [8, '8'],
                ],
                setEmpty: false,
                ignoreEmptyAdd: true,
                sort: false,
                colAttrs: { class:'slot' , style: 'max-width: 150px;' },
              },
              network: {
                type: "number",
                name: "Network",
                fieldAttrInput: { class: "mcc-mnc number", maxlength:6 /*,max:999999*/ },
                colAttrs: { style: 'max-width: 220px;' },
              },
              ratType:{
                type: 'select',
                name: 'RAT Type',
                associatedOption: this.options.modemRatType,
                setEmpty: false,
                ignoreEmptyAdd: true,
                default:"5G",
                sort: false,
                colAttrs: {cols:1},
              },
               bands:{
                type: 'text',
                inputFieldFormatter: 'explorerCellularBands',
              }

            },
          },
        },
        disableAllInputs: this.result.disableAllInputs,
        contentID: this.result.contentID,
      };
      return templateContent;
    },
  },
  methods: {
    updateFieldValue(index, value) {
      let localValue = {...this.value};
      //console.log(index, value);
      if(index=="slot" || index=="type"){
        this.$set(localValue["sim"], index, value);
      }else  if(index=="ratType" || index=="bands"){
        this.$set(localValue["radio"], index, value);
      }else {
        this.$set(localValue, index, value);
      }
      //this.$set(localValue, index, value);
      this.$emit('input', localValue);
     }
  },
};
</script>
<style scoped>

</style>